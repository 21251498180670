import { FORM_ERROR } from 'final-form';
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createAction,
  EntityState,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { QueryService } from '@vk-hr-tek/core/query';
import {
  UnitProcessor,
  UnitService,
  UnitType,
  UnitTypeOption,
  UnitNodeLabeled,
} from '@vk-hr-tek/core/units';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { showNotification } from '@vk-hr-tek/core/notifications';
import { History } from '@vk-hr-tek/core/history';

import {
  CreateEventOptionsList as OptionsResponse,
  CreateEventCompanyItem,
  EventListItem as EventListEntity,
  CreateEventResponse as CreateResponse,
  EventBatchEmployee,
  EmployeeItem,
  EventTypeItem,
  EventBatchItem,
  CreateEventTypeOptions,
  CreateEventCommonOptionsResponse,
} from '@app/gen/events';

import { ThunkExtra, ThunkValuesAndActions } from '../../../app/store';
import { EventsListService } from '../../services';
import { EventsRouter } from '../../types';
import { EventsState, EventsWithRootState } from '../events.state';
import {
  CreateEventDto,
  GetEventBatchOptionsDto,
  CreateEventBatchDto,
  GetCreateEventTypeOptionsDto,
  GetOptionsDto,
  GetCreatableEventTypesForEmployeeDto,
  GetCreateOptionsCompanyDto,
  GetOptionsEmployees,
} from '../../dto';

export const getCanCreateEvent = createAsyncThunk<
  boolean,
  undefined,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getCanCreateEvent',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(EventsListService);
      const res = await service.getCreateOptionsCompany({
        withEventTypes: true,
      });

      return !!res.items.length;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const setCreateItems = createAction<CreateEventCompanyItem[]>(
  'events/setCreateItems',
);

export const setCreateEventTypes = createAction<EventTypeItem[]>(
  'events/setCreateEventTypes',
);

export const createEvent = createAsyncThunk<
  CreateResponse,
  ThunkValuesAndActions<CreateEventDto>,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      const eventsService = inject(EventsListService);
      const result = await eventsService.createEvent(values);

      inject<EventsRouter>(EventsRouter).goToDetail(result.event_id);

      actions.resolve(null);

      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createEventCompany = createAsyncThunk<
  CreateResponse,
  ThunkValuesAndActions<CreateEventDto>,
  ThunkExtra<EventsWithRootState>
>(
  'createEventCompany',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      const eventsService = inject(EventsListService);
      const result = await eventsService.createEvent(values);

      inject<EventsRouter>(EventsRouter).goToDetail(result.event_id);

      actions.resolve(null);

      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getOptions = createAsyncThunk<
  OptionsResponse,
  GetOptionsDto | undefined,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getOptions',
  async (values, { rejectWithValue, extra: { inject } }) => {
    try {
      return await inject(EventsListService).getCreateOptions(values);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCreateEventTypeOptions = createAsyncThunk<
  CreateEventTypeOptions,
  GetCreateEventTypeOptionsDto,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getCreateEventTypeOptions',
  async (
    getCreateEventTypeOptionsDto,
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const {
        events: { createEventTypeOptions },
      } = getState();

      const { event_type_id: eventTypeId } = getCreateEventTypeOptionsDto;
      const optionById = createEventTypeOptions.options[eventTypeId];

      if (optionById) {
        return {
          assignable_roles: optionById.assignableRoles,
          copy_documents: optionById.copyDocuments,
          copy_attributes: optionById.copyAttributes,
        };
      }

      const service = inject(EventsListService);
      const result = await service.getCreateEventTypeOptions(
        getCreateEventTypeOptionsDto,
      );

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getBatchOptionsCompany = createAsyncThunk<
  {
    company: { id: string; name: string };
    eventType: { id: string; name: string; documentInfoRequired: boolean };
    employees: EventBatchEmployee[];
    existingBatches: EventBatchItem[];
    unitTypeOptions?: UnitTypeOption[];
    unitType: UnitType | null;
    rootUnit: UnitNodeLabeled | null;
    employeesLoadingError: boolean;
  },
  undefined,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getBatchOptionsCompany',
  async (_, { rejectWithValue, getState, dispatch, extra: { inject } }) => {
    try {
      const history = inject<History>(History);
      const batchOptions = inject(QueryService).parse(
        history.location.search,
      ) as GetEventBatchOptionsDto;

      const validator = inject(ValidationService);

      await validator.validateOrReject(batchOptions, GetEventBatchOptionsDto);

      const service = inject(EventsListService);
      const unitService = inject(UnitService);
      const unitProcessor = inject(UnitProcessor);
      const state = getState().events.creation;

      let items = state.items;

      dispatch(
        getCreateEventTypeOptions({
          event_type_id: batchOptions.eventTypeId,
          parent_event_id: batchOptions.parentEventId,
        }),
      );

      if (!items || !items.length) {
        const res = await service.getCreateOptionsCompany({
          withEventTypes: true,
        });

        items = res.items;

        dispatch(setCreateItems(items));
      }

      const selectedCompany = items.find(
        ({ company_id }) => company_id === batchOptions.companyId,
      );

      if (!selectedCompany) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      let eventTypes = state.eventTypes;

      if (!eventTypes || !eventTypes.length) {
        const res = await service.getCreateBatchEventTypes({
          companyId: batchOptions.companyId,
        });

        eventTypes = res.event_types;

        dispatch(setCreateEventTypes(eventTypes));
      }

      const selectedEventType = eventTypes.find(
        (eventType) => eventType.id === batchOptions.eventTypeId,
      );

      if (!selectedEventType) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      const { event_batches: existingBatches } =
        await service.getExistingBatchEvents(batchOptions);

      const { types } = await unitService.getUnitTypes(batchOptions);

      let rootUnit = null;
      let unitType = null;

      if (Array.isArray(types) && types.length) {
        unitType = types[0].type as UnitType;
        const unitTree = await unitService.getUnitTree({
          companyId: batchOptions.companyId,
          unitType,
        });

        rootUnit = unitTree.root_unit ?? null;
      }

      const { employees } = await service.getCreateBatchEmployees(batchOptions);

      if (!Array.isArray(employees)) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      return {
        company: {
          id: selectedCompany.company_id,
          name: selectedCompany.company_name,
        },
        eventType: {
          id: selectedEventType.id,
          name: selectedEventType.name,
          documentInfoRequired: !!selectedEventType.document_info_required,
        },
        employees,
        existingBatches: existingBatches || [],
        unitTypeOptions: types.map(
          ({ type: value, name: label }) =>
            ({
              value,
              label,
            } as UnitTypeOption),
        ),
        unitType,
        rootUnit: rootUnit ? unitProcessor.processUnitsTree(rootUnit) : null,
        employeesLoadingError: false,
      };
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getUpdateOfBatchOptionsCompanyForUnitType = createAsyncThunk<
  {
    rootUnit: UnitNodeLabeled | null;
    employees: EventBatchEmployee[];
  },
  { unitType: UnitType; companyId: string },
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/updateUnitType',
  async ({ unitType, companyId }, { rejectWithValue, extra: { inject } }) => {
    try {
      const history = inject<History>(History);
      const service = inject(EventsListService);
      const batchOptions = inject(QueryService).parse(
        history.location.search,
      ) as GetEventBatchOptionsDto;

      const unitService = inject(UnitService);
      const unitProcessor = inject(UnitProcessor);

      const { root_unit: rootUnit = null } = await unitService.getUnitTree({
        companyId,
        unitType,
      });

      const { employees } = await service.getCreateBatchEmployees(batchOptions);

      return {
        employees,
        rootUnit: rootUnit ? unitProcessor.processUnitsTree(rootUnit) : null,
      };
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getEmployeesForUnit = createAsyncThunk<
  { employees: EventBatchEmployee[] },
  GetEventBatchOptionsDto,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/updateUnit',
  async (batchOptions, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(EventsListService);

      const result = await service.getCreateBatchEmployees(batchOptions);

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createBatchEvents = createAsyncThunk<
  void,
  {
    values: CreateEventBatchDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/createBatchEvents',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const history = inject<History>(History);
      await inject(EventsListService).createBatchEvents(values);

      dispatch(
        showNotification(
          `Заявки (${values.employees.length}) успешно созданы!`,
        ),
      );

      actions.resolve(null);

      const location = history.location as {
        state?: { prev?: { pathname: string; search: string }[] };
      };

      if (location.state?.prev) {
        inject<EventsRouter>(EventsRouter).goToList(
          location.state.prev?.[0]?.search,
        );
      } else {
        inject<EventsRouter>(EventsRouter).goToList();
      }
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCanCreateBatchEventOrEventForAnotherEmployee = createAsyncThunk<
  CreateEventCommonOptionsResponse,
  { companyId: string },
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getCanCreateBatchEventOrEventForAnotherEmployee',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(EventsListService);

      const result =
        await service.getCanCreateBatchEventOrEventForAnotherEmployee(dto);

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getEventCreateCompaniesOptions = createAsyncThunk<
  CreateEventCompanyItem[],
  GetCreateOptionsCompanyDto,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getEventCreateCompaniesOptions',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(EventsListService);
      const res = await service.getCreateOptionsCompany(dto);

      return res.items;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCreateEventTypesOptions = createAsyncThunk<
  EventTypeItem[],
  GetOptionsEmployees,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getCreateEventTypesOptions',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(EventsListService);
      const res = await service.getCreateBatchEventTypes(dto);

      return res.event_types;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCreatableEventTypesForEmployee = createAsyncThunk<
  EventTypeItem[],
  GetCreatableEventTypesForEmployeeDto,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getCreatableEventTypesForEmployee',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(EventsListService);
      const res = await service.getCreatableEventTypesForEmployee(dto);

      return res.event_types;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const checkForEmployeesCount = createAsyncThunk<
  EmployeeItem[],
  { companyId: string; forAnother: boolean },
  ThunkExtra
>(
  'createEvent/checkForEmployeesCount',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(EventsListService);

      const res = await service.getEventEmployees({
        companyId: dto.companyId,
        forAnother: dto.forAnother,
        limit: 2,
        offset: 0,
      });

      return res.employees;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createEventWithEventTypeGroup = createAsyncThunk<
  CreateEventCompanyItem[] | void,
  undefined,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/createEventWithEventTypeGroup',
  async (_, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      const history = inject<History>(History);
      const { event_type_group: eventTypeGroup } = inject(QueryService).parse(
        history.location.search,
      );
      const service = inject(EventsListService);

      const companies = await service.getCreateOptionsCompany({
        withEventTypes: true,
      });

      if (companies.items.length === 1) {
        const companyId = companies.items[0].company_id;
        const employees = await service.getEventEmployees({
          companyId: companyId,
          forAnother: false,
          limit: 2,
          offset: 0,
        });

        if (employees.employees.length === 1) {
          const employeeId = employees.employees[0].id;
          const eventTypes = await service.getCreatableEventTypesForEmployee({
            eventTypeGroup,
            id: employeeId,
          });

          if (eventTypes.event_types.length === 1) {
            const eventTypeId = eventTypes.event_types[0].id;

            dispatch(
              createEvent({
                values: { employeeId, eventTypeId } as CreateEventDto,
                actions: { resolve: () => {}, reject: () => {} },
              }),
            );
          }
        }
      } else {
        return companies.items;
      }
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const creationReducers = (
  builder: ActionReducerMapBuilder<EntityState<EventListEntity> & EventsState>,
) => {
  builder.addCase(setCreateItems, (state, action) => {
    state.creation.items = action.payload;
  });

  builder.addCase(setCreateEventTypes, (state, action) => {
    state.creation.eventTypes = action.payload;
  });

  builder.addCase(getCanCreateEvent.pending, (state) => {
    state.canCreateEvent = false;
  });
  builder.addCase(getCanCreateEvent.fulfilled, (state, action) => {
    state.canCreateEvent = action.payload;
  });
  builder.addCase(getCanCreateEvent.rejected, (state) => {
    state.canCreateEvent = false;
  });

  builder.addCase(getCreateEventTypeOptions.pending, (state) => {
    state.createEventTypeOptions.status = 'loading';
    state.createEventTypeOptions.error = null;
  });
  builder.addCase(
    getCreateEventTypeOptions.fulfilled,
    (state, { payload, meta }) => {
      state.createEventTypeOptions.status = 'complete';
      state.createEventTypeOptions.options[meta.arg.event_type_id] = {
        ...(state.createEventTypeOptions.options[meta.arg.event_type_id] || {}),
        assignableRoles: payload.assignable_roles,
        copyDocuments: payload.copy_documents,
        copyAttributes: payload.copy_attributes,
      };
      state.createEventTypeOptions.error = null;
    },
  );
  builder.addCase(
    getCreateEventTypeOptions.rejected,
    (state, { payload, error, meta }) => {
      if (!meta.aborted) {
        state.createEventTypeOptions.status = 'failed';
        state.createEventTypeOptions.error =
          payload ||
          ({
            info: (error && error.message) || 'Что-то пошло не так',
            status: 500,
            source: 'client',
            title: 'Internal client error',
          } as AppError);
      }
    },
  );

  builder.addCase(getBatchOptionsCompany.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.selected = null;
    state.creation.error = null;
  });
  builder.addCase(getBatchOptionsCompany.fulfilled, (state, { payload }) => {
    state.creation.status = 'complete';
    state.creation.selected = payload;
    state.creation.error = null;
  });
  builder.addCase(
    getBatchOptionsCompany.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.selected = null;
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(
    getUpdateOfBatchOptionsCompanyForUnitType.pending,
    (state) => {
      state.creation.error = null;
      if (state.creation.selected) {
        state.creation.selected.employees = [];
        state.creation.selected.rootUnit = null;
      }
    },
  );
  builder.addCase(
    getUpdateOfBatchOptionsCompanyForUnitType.fulfilled,
    (state, { payload }) => {
      if (state.creation.selected) {
        const { rootUnit, employees } = payload;
        state.creation.selected.rootUnit = rootUnit;
        state.creation.selected.employees = employees;
        state.creation.selected.employeesLoadingError =
          !Array.isArray(employees);
      }
      state.creation.error = null;
    },
  );

  builder.addCase(
    getUpdateOfBatchOptionsCompanyForUnitType.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(getEmployeesForUnit.pending, (state) => {
    state.creation.error = null;
    if (state.creation.selected) {
      state.creation.selected.employees = [];
    }
  });
  builder.addCase(getEmployeesForUnit.fulfilled, (state, { payload }) => {
    if (state.creation.selected) {
      const { employees } = payload;
      state.creation.selected.employees = employees;
      state.creation.selected.employeesLoadingError = !Array.isArray(employees);
    }
    state.creation.error = null;
  });
  builder.addCase(getEmployeesForUnit.rejected, (state, { payload, error }) => {
    state.creation.status = 'failed';
    state.creation.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });

  builder.addCase(getOptions.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
    state.canCreateEvent = false;
  });
  builder.addCase(getOptions.fulfilled, (state, { payload }) => {
    state.creation.status = 'complete';
    state.creation.error = null;
    state.creation.options = payload.options;
    state.creation.items = payload.items;
    state.canCreateEvent = !!payload.items.length;
  });
  builder.addCase(getOptions.rejected, (state, { payload, error }) => {
    state.creation.status = 'failed';
    state.creation.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
    state.canCreateEvent = false;
  });

  builder.addCase(createEvent.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(createEvent.fulfilled, (state) => {
    state.creation.status = 'complete';
    state.creation.error = null;
  });
  builder.addCase(createEvent.rejected, (state, { payload, error }) => {
    state.creation.status = 'failed';
    state.creation.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });

  builder.addCase(createEventCompany.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(createEventCompany.fulfilled, (state) => {
    state.creation.status = 'complete';
    state.creation.error = null;
  });
  builder.addCase(createEventCompany.rejected, (state, { payload, error }) => {
    state.creation.status = 'failed';
    state.creation.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });

  builder.addCase(getEventCreateCompaniesOptions.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(
    getEventCreateCompaniesOptions.fulfilled,
    (state, { payload }) => {
      state.creation.status = 'complete';
      state.creation.items = payload;
    },
  );

  builder.addCase(
    getEventCreateCompaniesOptions.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(getCreateEventTypesOptions.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(
    getCreateEventTypesOptions.fulfilled,
    (state, { payload }) => {
      state.creation.status = 'complete';
      state.creation.eventTypes = payload;
    },
  );
  builder.addCase(
    getCreateEventTypesOptions.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(getCreatableEventTypesForEmployee.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(
    getCreatableEventTypesForEmployee.fulfilled,
    (state, { payload }) => {
      state.creation.status = 'complete';
      state.creation.employeeEventTypes = payload;
    },
  );
  builder.addCase(
    getCreatableEventTypesForEmployee.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(checkForEmployeesCount.pending, (state) => {
    state.creation.employees.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(checkForEmployeesCount.fulfilled, (state, { payload }) => {
    state.creation.employees.status = 'complete';
    state.creation.employees.list = payload;
  });
  builder.addCase(
    checkForEmployeesCount.rejected,
    (state, { payload, error }) => {
      state.creation.employees.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(
    getCanCreateBatchEventOrEventForAnotherEmployee.pending,
    (state) => {
      state.creation.status = 'loading';
      state.creation.error = null;
    },
  );
  builder.addCase(
    getCanCreateBatchEventOrEventForAnotherEmployee.fulfilled,
    (state, { payload }) => {
      state.creation.status = 'complete';
      state.creation.canCreateBatch = payload.has_batch;
      state.creation.canCreateForAnother = payload.creator_for_others;
    },
  );
  builder.addCase(
    getCanCreateBatchEventOrEventForAnotherEmployee.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );

  builder.addCase(createEventWithEventTypeGroup.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(
    createEventWithEventTypeGroup.fulfilled,
    (state, { payload }) => {
      state.creation.status = 'complete';

      if (payload) {
        state.creation.items = payload;
      }
    },
  );
  builder.addCase(
    createEventWithEventTypeGroup.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );
};
